export default function ({ $axios, i18n }) {
    // $axios.interceptors.request.use((request) => {
    //     return request;
    // });

    // $axios.interceptors.response.use((response) => {
    //     return response;
    // });

    $axios.onError((error) => {
        console.log('response error!');
        console.log(error.response.status);
    });

    $axios.defaults.baseURL = `https://${i18n.locales.find((x) => x.iso === i18n.locale).domain}/api`;
}
