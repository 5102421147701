import Vue from 'vue';

import Input from '@/components/UI/Inputs/Input';
import InputError from '@/components/UI/Inputs/InputError.vue';
import PButton from '@/components/UI/Buttons/PButton';
import PageIntro from '@/components/PageIntro';
import T from '@/components/UI/T/T';

Vue.component('Input', Input);
Vue.component('InputError', InputError);
Vue.component('PButton', PButton);
Vue.component('PageIntro', PageIntro);
Vue.component('T', T);
