export const state = () => ({
    exampleData: {
        en: [],
        hu: [],
    },
    price: null,
});

export const mutations = {
    setExampleData(state, payload) {
        state.exampleData[payload.lang] = payload.data;
    },
    setPrice(state, payload) {
        state.price = payload;
    },
};
