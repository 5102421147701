import Vue from 'vue';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { localize } from '@vee-validate/i18n';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

localize('en', {
    fields: {
        password: {
            email: 'Hey! Password cannot be empty',
        },
    },
});

extend('required', {
    ...required,
    message: localize({
        en: {
            required: 'Hey! Password cannot be empty',
        },
        hu: {
            required: 'Hey!',
        },
    }),
});

extend('email', {
    ...email,
    message: localize({
        en: {
            required: 'This is not an email format',
        },
        hu: {
            required: 'Nem okés!',
        },
    }),
});
