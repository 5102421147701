<template>
    <header class="fixed top-0 left-0 w-full bg-orange-light shadow-lg xl:overflow-hidden">
        <div class="container lg:max-w-[1440px] 3xl:max-w-[70vw]">
            <div class="flex items-center justify-between lg:justify-self-auto">
                <div
                    class="bg-grey-900 xl:pl-9 pr-3 xl:pr-15 w-max logo relative transition-all min-h-[3.75rem] flex items-center xl:min-h-[6.25rem] xl:pt-8 xl:pb-8"
                >
                    <Logo class="max-w-[7rem] xl:min-w-[9.6rem] flex items-center" />
                </div>
                <nav
                    class="flex xl:items-center flex-col xl:flex-row bg-grey-800 xl:bg-grey-900 xl:bg-transparent h-[calc(100vh--3.75rem)] xl:h-auto top-15 xl:top-0 justify-between w-full xl:transition-none text-white xl:translate-x-0 absolute xl:relative left-0 xl:left-auto pt-10 xl:pt-0 pb-60 xL:pb-12 xl:pb-0"
                    :class="{
                        'translate-x-0': $store.state.isMobileMenuOpen,
                        'translate-x-[-100%]': !$store.state.isMobileMenuOpen,
                        'transition-all': $device.isMobile,
                    }"
                >
                    <div
                        class="flex flex-col xl:flex-row xl:items-center xl:gap-x-10 font-Open-Sans-Bold gap-y-5 xl:gap-y-0 ml-6 xl:ml-18"
                    >
                        <div v-for="item in headerItems" :key="item.id" class="link-wrapper">
                            <a
                                v-if="item.type === 'section'"
                                :href="`/#${item.id}`"
                                class="link"
                                :class="{ 'link--active': item.id === activeHeaderItemId }"
                            >
                                <T :t="item.name" />
                            </a>
                            <NuxtLink
                                v-else
                                :to="localePath(item.id)"
                                class="link"
                                :class="{ 'link--active': item.id === activeHeaderItemId }"
                            >
                                <T :t="item.name" />
                            </NuxtLink>
                        </div>
                    </div>
                    <div
                        class="flex xl:items-center gap-x-8 ml-6 xl:ml-0 flex-col xl:flex-row gap-y-6 xl:gap-y-0 mt-8 xl:mt-0"
                    >
                        <a class="link" :href="`tel:${$t('generic.tel-number')}`"><T t="generic.tel-number" /></a>
                        <PButton
                            to="registration"
                            :label="$t('generic.registration')"
                            class="order-3 text-lg xl:text-sm xl:order-none w-max"
                        />
                        <LanguageSwitcher v-if="false" :has-title="$device.isMobile" />
                    </div>
                </nav>
                <HamburgerBtn class="block xl:hidden absolute right-0" />
            </div>
        </div>
    </header>
</template>

<script>
import { throttle } from 'throttle-debounce';
import Logo from '../UI/Logo';
import LanguageSwitcher from '../UI/Buttons/LanguageSwitcher';
import HamburgerBtn from '../UI/Buttons/HamburgerBtn';

export default {
    name: 'Header',
    components: {
        Logo,
        LanguageSwitcher,
        HamburgerBtn,
    },
    data() {
        return {
            isActive: false,
            observer: null,
            scrollHandler: throttle(50, this.handleScroll),
            headerItems: [
                {
                    id: 'benefits',
                    name: 'header.why-choose-us',
                    type: 'section',
                },
                {
                    id: 'product',
                    name: 'header.product',
                    type: 'section',
                },
                {
                    id: 'prices',
                    name: 'header.prices',
                    type: 'section',
                },
                {
                    id: 'contact-for-schools',
                    name: 'header.contact',
                    path: 'contact-for-schools',
                    type: 'page',
                },
            ],
            activeHeaderItemId: null,
            isPageScrolled: false,
        };
    },

    watch: {
        $route(to, from) {
            this.$store.commit('toggleMobileMenu', false);
            this.activeHeaderItemId = to.hash.slice(1);
            if (to.fullPath === '/') {
                this.observeSections();
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollHandler);
    },
    mounted() {
        window.addEventListener('scroll', this.scrollHandler);
        this.observeSections();
    },

    methods: {
        observeSections() {
            const sections = document.querySelectorAll('.index-section');

            const observer = new IntersectionObserver((entries) => {
                for (const entry of entries) {
                    if (entry.isIntersecting) {
                        this.activeHeaderItemId = entry.target.id;
                    }
                }
            });
            sections.forEach((section) => observer.observe(section));
        },
        handleScroll() {
            if (window.scrollY === 0) {
                this.isPageScrolled = false;
                this.activeHeaderItemId = null;
            } else if (!this.isPageScrolled && window.scrollY > 0) {
                this.isPageScrolled = true;
            }
        },
    },
};
</script>

<style scoped>
.link-wrapper {
    &:hover {
        .link {
            @apply -translate-y-1;
        }
    }
}
.link {
    @apply font-Open-Sans-Bold text-xl xl:text-lg transition-all relative flex lg:justify-center items-center h-full block;
    &::before {
        content: '';
        @apply absolute h-[3px] bg-white max-w-0 -bottom-2 transition-all w-full pointer-events-none rounded-full;
    }
    &:hover {
        &::before {
            @apply max-w-[1rem];
        }
    }

    &.nuxt-link-active,
    &--active {
        @apply -translate-y-1;
        &::before {
            @apply max-w-[6rem];
        }
    }
}
.logo {
    &::before {
        content: '';
        @apply absolute bg-grey-900 h-full w-screen right-full top-0 mr-[-1px] lg:mr-0;
    }
    &::after {
        content: '';
        @apply inline-block w-0 h-0 border-solid absolute right-[-20px] top-0;
        border-width: 100px 20px 0 0;
        border-color: #2a2c33 transparent transparent transparent;
        @media (max-width: 1280px) {
            border-width: 60px 20px 0 0;
        }
    }
}
</style>
