<script>
export default {
    name: 'Message',
    functional: true,
    props: {
        t: {
            type: String,
            required: false,
            default: '',
        },
        tc: {
            type: String,
            required: false,
            default: '',
        },
    },
    render(createElement, context) {
        const tKey = context.props.tc ? context.props.tc : context.props.t;
        const slotVNode = context.slots().default;

        let isTranslationMissing = false;

        if (!slotVNode[0].text.trim().length) {
            isTranslationMissing = true;
            slotVNode[0].text = tKey;
        }

        if (context.parent.$cookies.get('show_translation_keys')) {
            return createElement('span', { class: 'relative' }, [
                slotVNode,
                createElement(
                    'span',
                    {
                        class: `translation-key ${isTranslationMissing ? 'translation-key--missing-value' : ''}`,
                    },
                    tKey
                ),
            ]);
        } else {
            return slotVNode;
        }
    },
};
</script>

<style scoped>
.translation-key {
    @apply absolute bottom-1 left-0 translate-y-full pointer-events-none font-Open-Sans-Regular text-xs px-1 rounded bg-white text-black border border-grey-900 border-opacity-25 hover:z-10;
    &--missing-value {
        @apply bg-error text-white;
    }
}
</style>
