<template>
    <div>
        <div class="h-[calc(100vh+3.75rem)] flex items-center justify-center bg-image">
            <div class="container">
                <div class="flex items-center justify-center flex-col mb-25">
                    <div v-if="error.statusCode === 404">
                        <h1 class="text-white font-Exo-ExtraBold text-[4.25rem] lg:text-[5.75rem] mb-9 lg:mb-11">
                            {{ error.statusCode }}
                        </h1>
                        <h2 class="font-Open-Sans-Bold text-white mb-8 lg:mb-10 text-center text-sm lg:text-lg">
                            <T t="feedback-messages.errors.page-not-found" />
                        </h2>
                    </div>
                    <div v-else class="font-Open-Sans-Bold text-white mb-12">
                        <div class="font-Exo-ExtraBold text-[4.25rem] lg:text-[5.75rem] text-center mb-4">
                            {{ error.statusCode }}
                        </div>
                        {{ error.message }}
                    </div>

                    <NuxtLink class="text-white text-sm lg:text-lg relative link flex justify-center" to="/">
                        <T t="generic.back-to-home-page" />
                    </NuxtLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error',
    layouts: 'Default',
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>
.bg-image {
    @apply bg-cover bg-center;
    background-image: url('~/assets/images/error_bg.jpg');
}
.link {
    &::before {
        content: '';
        @apply absolute -bottom-2 w-full lg:w-1/2 transition-all h-[1px] block bg-white;
    }
    &:hover {
        &::before {
            @apply w-full;
        }
    }
}
</style>
