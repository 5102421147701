import genericUrls from './generic.json';

export default function ({ redirect, route, $axios }) {
    if (process.server) {
        const redirectUrl = genericUrls.data.find((data) => data.old === route.path);

        if (redirectUrl) {
            return redirect(301, redirectUrl.new);
        }
    }
}
