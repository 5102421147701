export const state = () => ({
    scrollY: 0,
    host: null,
});

export const getters = {};

export const mutations = {
    setScrollY(state, payload) {
        state.scrollY = payload;
    },
    setHost(state, payload) {
        state.host = payload;
    },
};

export const actions = {
    nuxtServerInit({ dispatch, commit, state }, { req }) {
        commit('setHost', req.headers.host);
    },
};
