export default {
    head() {
        const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });

        return {
            htmlAttrs: i18nHead.htmlAttrs,
            title: this.$t('meta.site_title'),
            meta: [
                ...i18nHead.meta,
                {
                    hid: 'title',
                    name: 'title',
                    content: this.$t('meta.site_title'),
                },
                {
                    hid: 'og:title',
                    property: 'og:title',
                    content: this.$t('meta.site_title'),
                },
                { property: 'og:site_name', content: this.$t('meta.site_title') },
                {
                    hid: 'og:description',
                    property: 'og:description',
                    content: this.$t('meta.og_description'),
                },
                {
                    hid: 'description',
                    name: 'description',
                    content: this.$t('meta.og_description'),
                },
            ],
            link: [
                ...i18nHead.link,
                { rel: 'icon', type: 'image/x-icon', href: `/favicon/${this.$i18n.locale}/favicon.ico` },
                {
                    rel: 'apple-touch-icon',
                    sizes: '180x180',
                    href: `/favicon/${this.$i18n.locale}/apple-touch-icon.png`,
                },
                {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '32x32',
                    href: `/favicon/${this.$i18n.locale}/favicon-32x32.png`,
                },
                {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '16x16',
                    href: `/favicon/${this.$i18n.locale}/favicon-16x16.png`,
                },
                { rel: 'mask-icon', href: `/favicon/${this.$i18n.locale}/safari-pinned-tab.svg`, color: '#e31e26' },
            ],
        };
    },
};
