<template>
    <div id="__layout__content" class="flex flex-col min-h-screen relative z-0">
        <Header class="z-10" />

        <div class="relative z-0">
            <Nuxt />
        </div>

        <Footer class="relative z-5" />

        <GridOverlay />

        <CookieBanner v-if="!$device.isCrawler" is-compact class="z-40" />
    </div>
</template>

<script>
import Header from '@/components/Layout/Header';
import Footer from '@/components/Layout/Footer';
import GridOverlay from '@/components/Layout/GridOverlay';
import CookieBanner from '@/components/Layout/CookieBanner';
import meta from '@/mixins/meta';

export default {
    name: 'Default',
    components: {
        Header,
        Footer,
        CookieBanner,
        GridOverlay,
    },
    mixins: [meta],
    middleware: ['init'],
};
</script>
