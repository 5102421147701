import Vue from 'vue';
import 'primeicons/primeicons.css';

import PrimeVue from 'primevue/config';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber/InputNumber';
import Checkbox from 'primevue/checkbox';
import Textarea from 'primevue/textarea';

Vue.use(PrimeVue);

Vue.component('InputText', InputText);
Vue.component('InputNumber', InputNumber);
Vue.component('Textarea', Textarea);
Vue.component('Checkbox', Checkbox);
Vue.component('Dropdown', Dropdown);
Vue.component('Calendar', Calendar);
Vue.component('Button', Button);
