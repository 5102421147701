export default ({ app, $config, route, req, i18n }) => {
    //
    //
    // Add meta tags

    const meta = [];

    let host;

    if (process.server) {
        host = req.headers['x-forwarded-host'] || req.headers.host;
    } else {
        host = window.location.origin;
    }

    if ($config.nodeENV !== 'development' || !/^https/.test(host)) {
        host = 'https://' + host;
    }

    meta.push({
        hid: 'og:image',
        property: 'og:image',
        content: `${host}/og-images/${i18n.locale}.png`,
    });

    meta.push({
        hid: 'og:image:url',
        property: 'og:image:url',
        content: `${host}/og-images/${i18n.locale}.png`,
    });

    meta.push({
        property: 'og:image:type',
        content: 'image/png',
    });

    meta.push({
        property: 'og:url',
        content: host + route.fullPath,
    });

    meta.push({
        name: 'robots',
        content: $config.nuxtENV === 'production' && host && !/melkweg|mlkwg/.test(host) ? 'all' : 'noindex, nofollow',
    });

    app.head.meta.push(...meta);
};
