<template>
    <div class="mt-33 lg:mt-48 grid grid-cols-12">
        <div class="col-span-12" :class="wide ? 'lg:col-span-10' : 'lg:col-span-7'">
            <h1 class="font-Exo-Bold text-[1.875rem] lg:text-5xl">{{ title }}</h1>
        </div>
        <div class="col-span-12 lg:col-start-1" :class="wide ? 'lg:col-span-8' : 'lg:col-span-5'">
            <h2 class="font-Open-Sans-Regular mt-4 lg:mt-14 text-sm lg:text-base mb-20 lg:mb-24">{{ subtitle }}</h2>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageIntro',
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
            default: null,
        },
        wide: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>
