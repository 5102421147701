<template>
    <button
        class="w-8 h-8 relative flex flex-col justify-center items-center"
        :class="!$store.state.isMobileMenuOpen || 'is-open'"
        @click="$store.commit('toggleMobileMenu', (isMenuOpen = !isMenuOpen))"
    >
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
    </button>
</template>

<script>
import { enableBodyScroll, disableBodyScroll } from '@/static/scripts/body-scroll-lock.min';

export default {
    name: 'HamburgerButton',
    data() {
        return {
            isMenuOpen: false,
        };
    },
    watch: {
        '$store.state.isMobileMenuOpen': function (from, to) {
            if (from) {
                disableBodyScroll(document.querySelector('body'));
            } else {
                enableBodyScroll(document.querySelector('body'));
            }
        },
    },
    methods: {
        toggleMobileMenu() {
            this.store.commit('toggleMobileMenu', (this.isMenuOpen = !this.isMenuOpen));
        },
    },
};
</script>

<style scoped>
.bar {
    @apply w-[18px] absolute border border-white origin-center transition;
    &:nth-of-type(1) {
        @apply translate-y-[5px];
    }
    &:nth-of-type(2) {
        @apply opacity-100;
    }
    &:nth-of-type(3) {
        @apply translate-y-[-5px];
    }
}

.is-open {
    .bar {
        &:nth-of-type(1) {
            @apply translate-y-0 rotate-45;
        }
        &:nth-of-type(2) {
            @apply opacity-0;
        }
        &:nth-of-type(3) {
            @apply translate-y-0 -rotate-45;
        }
    }
}
</style>
