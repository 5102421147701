export const state = () => ({
    windowWidth: 0,
    isMobileMenuOpen: false,
    currentOrderId: null,
});

export const getters = {
    getWindowWidth(state) {
        return state.windowWidth;
    },
};

export const mutations = {
    setWindowWidth(state, payload) {
        state.windowWidth = payload;
    },
    setCurrentOrderId(state, payload) {
        state.currentOrderId = payload;
    },
    toggleMobileMenu(state, payload) {
        state.isMobileMenuOpen = payload;
    },
};
