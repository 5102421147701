<template>
    <div>
        <div class="container">
            <div
                v-if="$i18n.locale === 'hr'"
                class="w-full h-12 bg-[#E8EDF2] mb-12 lg:mb-20 lg:mt-12 flex items-center justify-center py-9"
            >
                <object type="image/svg+xml" :data="require(`~/assets/images/icons/eur-icon.svg`)"></object>
                <span class="font-Open-Sans-Bold text-lg ml-5"><T t="product_detail.croatian_price" /></span>
            </div>
        </div>
        <footer class="bg-grey-900 py-12 lg:py-10">
            <div class="container">
                <div class="flex flex-col lg:flex-row lg:justify-between mb-10">
                    <Logo class="lg:w-2/12 mb-9 lg:mb-0 max-w-[11.125rem]" />
                    <div class="lg:w-4/12 text-white lg:text-right flex flex-col gap-y-3">
                        <span class="font-Open-Sans-Regular"><T t="generic.company-name" /></span>
                        <a
                            :href="$i18n.locales.find((x) => x.code === $i18n.locale).google_maps_url"
                            class="font-Open-Sans-Regular"
                            target="_blank"
                        >
                            <span> <T t="generic.address-title" /></span>:&nbsp;<span class="underline">
                                <T t="generic.address" />
                            </span>
                        </a>
                        <a :href="`tel:${$t('generic.tel-number')}`" class="font-Open-Sans-Regular">
                            <span><T t="generic.tel" /></span>:&nbsp;
                            <span class="underline"><T t="generic.tel-number" /> </span>
                        </a>
                        <a :href="`mailto:${$t('generic.email-address')}`" class="font-Open-Sans-Regular">
                            <span><T t="generic.email" /></span>:&nbsp;
                            <span class="underline"><T t="generic.email-address" /></span>
                        </a>
                    </div>
                </div>
                <div
                    class="flex flex-col lg:flex-row lg:items-center text-white gap-x-14 pt-9 lg:pt-10 border-t border-white border-opacity-15 gap-y-2 lg:gap-y-0"
                >
                    <a download class="hover:underline" :href="`/pdf/${$i18n.locale}/privacy-policy.pdf`">
                        <T t="generic.privacy-policy" />
                    </a>

                    <a
                        v-if="$i18n.locale === 'hu'"
                        download
                        class="hover:underline"
                        :href="`/pdf/${$i18n.locale}/sulibox_felhasznalasi_feltetelek.pdf`"
                    >
                        Felhasználási feltételek
                    </a>

                    <span>
                        <span> <T t="footer.more-information" /> </span>&nbsp;
                        <a :href="`tel:${$t('generic.tel-number')}`" class="underline hover:underline">
                            <T t="generic.tel-number" />
                        </a>
                    </span>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import Logo from '../UI/Logo';

export default {
    name: 'Footer',
    components: {
        Logo,
    },
};
</script>

<style scoped></style>
