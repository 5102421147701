<template>
    <a v-if="to && external" :href="to" :class="setButtonStyle" target="_blank" rel="noopener noreferrer">
        {{ label }}
    </a>
    <NuxtLink v-else-if="to" :to="localePath(to)" :class="setButtonStyle">{{ label }}</NuxtLink>
    <button v-else :type="type" :disabled="disabled" class="" :class="setButtonStyle">
        {{ label }} <span v-if="icon" :class="`ml-2 ${icon}`"></span>
    </button>
</template>

<script>
export default {
    name: 'Button',
    props: {
        to: {
            type: String,
            required: false,
            default: null,
        },
        link: {
            type: Boolean,
            required: false,
        },
        label: {
            type: String,
            required: true,
            default: 'Button label',
        },
        type: {
            type: String,
            required: false,
            default: null,
        },
        external: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        appearance: {
            type: String,
            required: false,
            default: 'primary',
        },
        blue: {
            type: Boolean,
            required: false,
            default: false,
        },
        icon: {
            type: String,
            required: false,
            default: null,
        },
        lg: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        setButtonStyle() {
            const styles = ['uppercase', 'transition'];
            if (this.appearance === 'primary') {
                styles.push('rounded-full font-Open-Sans-Semibold');
            } else if (this.appearance === 'download-link') {
                styles.push(
                    'p-0 font-Source-Sans-Pro-Bold text-lg text-blue-100 bg-transparent flex items-center download-link'
                );
            } else if (this.appearance === 'link') {
                styles.push('p-0 font-Source-Sans-Pro-Bold text-blue-100 bg-transparent');
            }

            if (this.blue) {
                styles.push('bg-blue-100 hover:bg-blue-200 text-white');
            } else if (this.appearance === 'primary') {
                styles.push('bg-white hover:bg-grey-100 text-black');
            }

            if (this.lg) {
                styles.push('py-2 lg:py-4  px-5 lg:px-15');
            } else if (this.appearance === 'primary') {
                styles.push('py-[0.625rem] px-5');
            }

            return styles.join(' ');
        },
    },
};
</script>

<style scoped>
button:disabled {
    opacity: 0.4;
}
.download-link {
    &::before {
        content: '';
        @apply block w-5 h-6 bg-center bg-contain bg-no-repeat mr-4;
        background-image: url('~/assets/images/icons/download-icon.svg');
    }
}
</style>
