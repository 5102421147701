<template>
    <div class="flex gap-x-2 items-center">
        <button
            class="flex items-center gap-x-2 relative justify-center"
            :class="getLocale.lang"
            @click="setLocale(getLocale.lang)"
        >
            <span v-if="hasTitle" class="font-Open-Sans-Bold text-lg xl:text-sm" :class="light && 'text-white'">
                {{ getLocale.title }}
            </span>
        </button>
    </div>
</template>

<script>
export default {
    name: 'LanguageSwitcher',
    props: {
        hasTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        light: {
            hasTitle: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
    },
    data() {
        return {
            currentLang: null,
        };
    },
    computed: {
        getLocale() {
            if (this.$i18n.locale === 'en') {
                return { lang: 'hu', title: 'Magyar' };
            } else {
                return { lang: 'en', title: 'English' };
            }
        },
    },
    methods: {
        setLocale(lngCode) {
            this.$i18n.setLocale(lngCode);
        },
    },
};
</script>

<style scoped>
.hu,
.en {
    &::before {
        content: '';
        @apply w-8 h-3 bg-center bg-contain bg-no-repeat block;
    }
}
.hu {
    &::before {
        background-image: url('~/assets/images/icons/flag-icon--hu.svg');
    }
}
.en {
    &::before {
        background-image: url('~/assets/images/icons/flag-icon--en.svg');
    }
}
button {
    &::after {
        @media (min-width: 1024px) {
            content: '';
            @apply w-9 h-9 rounded-full z-[-1] bg-white bg-opacity-20 transition-all absolute;
        }
    }
    &:hover {
        &::after {
            @apply bg-opacity-60;
        }
    }
}
</style>
