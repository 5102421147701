<template>
    <NuxtLink to="/">
        <object
            type="image/svg+xml"
            class="max-w-[100px] xl:max-w-[unset]"
            :data="require(`~/assets/images/logos/logo-${$i18n.locale}.svg`)"
        ></object>
    </NuxtLink>
</template>

<script>
export default {
    name: 'Logo',
};
</script>
