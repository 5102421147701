import dev from './development/hu.json';
const merge = require('deepmerge');

export default async ({ $axios, i18n }) => {
    const { domain } = i18n.locales.find((x) => x.iso === 'hu');

    return await $axios
        .$get(`https://${domain}/api/translate`)
        .then((res) => {
            return merge(res, dev);
        })
        .catch((err) => {
            console.log(err);
        });
};
